
import React, { Component } from 'react';
import {PureComponent} from 'react';


export default class BearInfo extends PureComponent {
  render() {
    const {info} = this.props;
    //const displayName = `${info.city}, ${info.state}`;

    return (
      <div>
          <p>Sighting Date: {info.properties.time}</p>
          <p>Latitude: {info.geometry.coordinates[1]}</p>
          <p>Longitude: {info.geometry.coordinates[0]}</p>
      </div>
    );
  }
}