import React, { Component , createRef} from 'react';


import exifr from 'exifr'

import MapGL, {Marker,Source, Layer, FlyToInterpolator} from 'react-map-gl';
import { json } from 'd3-request';


import { Modal , Button} from 'react-bootstrap';
import MapPin from './mappin';
//import LocationPicker from './lib';
const axios = require('axios').default;

const labelStyle = {
  paddingRight: '10px',
  verticalAlign: 'top'
}

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2VnYTE5IiwiYSI6ImNrY2szMzJtazAycHkyeHB3emJrNndqOWUifQ.nAEYEFHm1ajdNmyY0Jfxgg'; // Set your mapbox token here

export class ReportSighting extends Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();
        this.state = {
            
            open: false,
            sighting_id: null,
            viewport: {
                latitude: 38.6,
                longitude: -92.3341,
                zoom: 5,
                bearing: 0,
                pitch: 0,
                width: 350,
                height: 300
            },
            marker: {
                latitude: 37.785164,
                longitude: -100
            }
          };
        this.Example = this.Example.bind(this)
        this.handleSightingSubmit = this.handleSightingSubmit.bind(this);
        
       
    }
     
    
    
        
    static displayName = ReportSighting.name;
   
    
    
    handleChange = event => {
        this.setState({ location: event.target.value });
    };

    

    async setFile({
        target: {
          files: [file]
        }
      }) 
      {
        
        this.setState({ file: file, fileURL: URL.createObjectURL(file) });
        
        if (file && file.name) {
            try 
            {
                let {latitude, longitude} = await exifr.gps(file)

                //alert(latitude + ' ' + longitude);
                this.setState({ lat: latitude, lng: longitude });
            }
            catch
            {
                let longitude = this.randomFromInterval(-95, -91.0);
                let latitude =  this.randomFromInterval(37.0,41);
                this.setState({ lat: latitude, lng: longitude });
            }
            
            
            let llat = this.state.lat;
            let llng = this.state.lng;
            this.setState ({marker:  {
                
                latitude: llat,
                longitude: llng
                }});
            
            this._onViewportChange({
                longitude: llng,
                latitude: llat,
                zoom: 11,
                transitionInterpolator: new FlyToInterpolator({speed: 1.2}),
                transitionDuration: 'auto'
              });
        }
    
    }

    
    randomFromInterval(min, max) { // min and max included 
        return ( Math.random() * (max - min) + min);
      }
    handleSightingSubmit(event) {
        
        var allowedExtensions =  
                    /(\.jpg|\.jpeg|\.png)$/i; 
              
        if (!allowedExtensions.exec(this.state.file.name)) { 
            alert("Please select a .jpg, .jpeg or .png file")
            return;
            }
        if (this.state.file.size > 1000000) {
            alert("Please upload a image smaller than 1MB");
            return
        }

        this.inputRef.current.setAttribute("disabled", "disabled");
        this.handleSightingSubmit2(event);
        
       
    }
    handleSightingSubmit2(event) {
        
        const url = 'https://5emo37ia5h.execute-api.us-east-1.amazonaws.com/prod/savesighting';

        const config = {
            timeout: 40000,
            headers: {
                'content-type': 'application/json',
            },
        };
        
        var that = this;

        axios.post(url, {
            //Sighting_UUID: sighting_uuid,
            OriginalFileName: this.state.file.name,
            Latitude: this.state.marker.latitude,
            Longitude: this.state.marker.longitude
          }, config)
            .then(function (response) {
                
                
                
                let sighting_uuid = response['data'];
                that.handleSightingSubmit3(event,sighting_uuid);
                //alert(sighting_uuid);
                //alert(JSON.stringify(response['data']['records'][0][0].longValue));
                //let id = response['data']['records'][0][0].longValue
                //that.setState({sighting_id : id})
                
            })
            .catch(error => {
                    alert(error);
            })
            .then(function () {
                that.inputRef.current.removeAttribute("disabled" );
            });


        event.preventDefault();
   }
  
    handleSightingSubmit3(event, sighting_uuid) {

         //this.state.markers.map(n => { alert(n.position.lat + '----' + n.position.lng)});
        
         //alert('uploading image!!');
         const url = 'https://5emo37ia5h.execute-api.us-east-1.amazonaws.com/prod/savesightingimage';

         const formData = new FormData();
         
         formData.append('body', this.state.file);
         //formData.append('file_name', this.state.file.name);
         
         const config = {
             timeout: 60000,
             headers: {
                 'content-type': 'multipart/form-data',// undefined ,//'multipart/form-data',
                 'X-File-Name' : this.state.file.name,
                 'X-Sighting-Id' : sighting_uuid
             },
         };
        
         var that = this;
         try
         {
            axios.post(url, formData, config)
                .then(function (response) {
                    
                    
                    //sighting_uuid = response['data'];
                    that.setState({
                      file: null,
                      fileURL: null,
                      fileInputKey: Date.now()
                  });
                    console.log(response);
                    that.openModal();
                    
                })
                .catch(error => {
                    
                    console.log(error.message);
                    alert('failed: ' + error.message);
                })
                .then(function () {
                    
                });


        }
        catch 
        {
            alert('error');
        }
         event.preventDefault();
         
    }

    _logDragEvent(name, event) {
        this.setState({
          events: {
            ...this.state.events,
            [name]: event.lngLat
          }
        });
      }
    
      _onMarkerDragStart = event => {
        this._logDragEvent('onDragStart', event);
      };
    
      _onMarkerDrag = event => {
        this._logDragEvent('onDrag', event);
      };
    
      _onMarkerDragEnd = event => {
        this._logDragEvent('onDragEnd', event);
        this.setState({
          marker: {
            longitude: event.lngLat[0],
            latitude: event.lngLat[1]
          }
        });
      };
    componentDidMount()
    {
        //this.setState({map_data: currentMarker});
    }
    _onViewportChange = viewport =>
    this.setState({
    viewport: {...this.state.viewport, ...viewport}
    });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

     Example() {
        
        const {isOpen} = this.state;
        return (
          <>
           
            <Modal show={isOpen} onHide={this.closeModal} >
              <Modal.Header closeButton>
                <Modal.Title>Bear Sighting</Modal.Title>
              </Modal.Header>
                <Modal.Body>Thank you for your sighting submission. Review in progress...<br/><a href="https://www.w3schools.com/">View Status</a></Modal.Body>
              <Modal.Footer>
                
                <Button variant="primary" onClick={this.closeModal} >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
     
  render () {
    const {viewport, marker} = this.state;
    
      return (
        <div>
           
            <div>
                <form>
                    <div>
                        <div>
                            <label style = {labelStyle}>Select an image: </label>
                            <br></br>
                            <input name="bearimage" type="text" placeholder="select bear image" value={this.state.location} onChange={this.handleChange}/>
                            <input type="file" key={this.state.fileInputKey} 
                            accept=".jpg, .png, .jpeg"
                            onChange={e => this.setFile(e)} />
                        </div>
                        <hr />
                        <div>
                            <label style = {labelStyle}>Image Preview: </label>
                            <br></br>
                             <img style = {{ width:180, height:125}} src={this.state.fileURL}/>
                        </div>
                        
                    </div>
                   
                 </form>
            </div>
            <hr/>
            <div>
                <div>
                  <label style = {labelStyle}>Review location: </label>
                  <MapGL
                      {...viewport}
                      onViewportChange={(viewport) => this.setState({viewport})}
                      mapboxApiAccessToken={MAPBOX_TOKEN}
                      mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                  >   
                    
                      <Marker
                          longitude={marker.longitude}
                          latitude={marker.latitude}
                          offsetTop={-20}
                          offsetLeft={-10}
                          draggable
                          onDragStart={this._onMarkerDragStart}
                          onDrag={this._onMarkerDrag}
                          onDragEnd={this._onMarkerDragEnd}
                          >
                          <MapPin size={20} />
                      </Marker>

                  </MapGL>
                </div>
                <div>
                    <p>Latitude: {this.state.marker.latitude} Longitude: {this.state.marker.longitude}</p>
                     
                </div>
            </div>
            <hr/>
           
            <div>
                <button className="btn btn-primary"  ref={this.inputRef}  onClick={this.handleSightingSubmit}>Send Sighting</button>
            </div>
            <this.Example />
  
        </div>
    );
  }
}
