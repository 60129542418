import React, { Component } from 'react';
import { post } from 'axios';
import {json as requestJson, json} from 'd3-request';

import BearInfo from './bear-info';
import BearPins from './bearpins';


import MapGL, {Source, Layer, Popup} from 'react-map-gl';
import ControlPanel from './control-panel';
import {render} from 'react-dom';
//import LocationPicker from './lib';
const axios = require('axios').default;

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2VnYTE5IiwiYSI6ImNrY2szMzJtazAycHkyeHB3emJrNndqOWUifQ.nAEYEFHm1ajdNmyY0Jfxgg'; // Set your mapbox token here

var geojsonFeature = {"type": "FeatureCollection", "features": [{"type": "Feature", "geometry": {"type": "Point", "coordinates": [ -92.3341, 38.9517, 0]}, "properties": {"city": "Columbia, MO"}}]};

function filterFeaturesByDay(featureCollection, time) {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const features = featureCollection.features.filter(feature => {
    const featureDate = new Date(feature.properties.time);
    return (
      featureDate.getFullYear() === year &&
      featureDate.getMonth() === month &&
      featureDate.getDate() === day
    );
  });
  return {type: 'FeatureCollection', features};
}
export default class MapContainer extends Component {
    constructor(props) {
        super(props);
        const current = new Date().getTime();

        this.state = {
          popupInfo: null,
          viewport: {
            latitude: 38.9517,
            longitude: -92.3341,
            zoom: 7,
            bearing: 0,
            pitch: 0
          },
          allDay: true,
          startTime: current,
          endTime: current,
          selectedTime: current
          };
        

          this._handleChangeDay = this._handleChangeDay.bind(this);
          this._handleChangeAllDay = this._handleChangeAllDay.bind(this);
        
    }


    static displayName = MapContainer.name;

    _onViewportChange = viewport => this.setState({viewport});
    componentDidMount() {
      requestJson(
       
        'https://5emo37ia5h.execute-api.us-east-1.amazonaws.com/prod/sightinggeo/',
        (error, response) => {
          if (!error && response.features != undefined && response.features.length > 0) {
           
            const features = response.features;
            const startTime2 =  Date.parse( features[0].properties.time);
            
            const endTime2 = Date.parse(features[features.length - 1].properties.time);
           
            const startTime3 = new Date(startTime2);
            const year = startTime3.getFullYear();
            const month = startTime3.getMonth();
            const day = startTime3.getDate();
            const startTime4 = new Date(year,month,day,0,0,0,0)
            
            const endTime3 = new Date(endTime2);
            const yearEnd = endTime3.getFullYear();
            const monthEnd = endTime3.getMonth();
            const dayEnd = endTime3.getDate();
            const endTime4 = new Date(yearEnd,monthEnd,dayEnd,0,0,0,0)

            this.setState({
              bears: response,
              data: response,
              endTime:endTime4,
              startTime: startTime4,
              selectedTime: endTime4
            });
          }
        }
      );
    }

    
  
    _onClickMarker = bear => {
      
      this.setState({popupInfo: bear});
    };
  
  _renderPopup() {
    const {popupInfo} = this.state;

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          time= {Date.parse(popupInfo.properties.time)}
          longitude={popupInfo.geometry.coordinates[0]}
          latitude={popupInfo.geometry.coordinates[1]}
          closeOnClick={false}
          onClose={() => this.setState({popupInfo: null})}
        >
          <BearInfo info={popupInfo} />
        </Popup>
      )
    );
  }
  _handleChangeDay = time => {
      
      this.setState({selectedTime: time});
      if (this.state.bears) {
        this.setState({data: filterFeaturesByDay(this.state.bears, time)});
      }
    };

    _handleChangeAllDay = allDay => {
      
      this.setState({allDay});
      if (this.state.bears) {
        this.setState({
          data: allDay
            ? this.state.bears
            : filterFeaturesByDay(this.state.bears, this.state.selectedTime)
        });
      }
    };
  render () {
    const {viewport, data, allDay, selectedTime, startTime, endTime} = this.state;
    
    //alert (JSON.stringify( selectedTime));
    return (
      <div style={{height: '100vh', position: 'relative'}}>
          
           <MapGL
          {...viewport}
          width="100%"
          height="100%"
          
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onViewportChange={this._onViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          {data && (
          <BearPins data={data} onClick={this._onClickMarker} />
          )}
          {this._renderPopup()}

          
        </MapGL>
        <ControlPanel
              containerComponent={this.props.containerComponent}
              startTime={startTime}
              endTime={endTime}
              selectedTime={selectedTime}
              allDay={allDay}
              
              onChangeDay={this._handleChangeDay}
              onChangeAllDay={this._handleChangeAllDay}
              >
          </ControlPanel>
        </div>
    );
  }

 
}


export function renderToDom(container) {
  render(<MapContainer />, container);
}

