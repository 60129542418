import React, { Component } from 'react';

export class SightingStatus extends Component {
  static displayName = SightingStatus.name;

  constructor(props) {
    super(props);
    this.state = { SightingStatus: [], loading: true };
  }

  componentDidMount() {
    this.populateSightingStatusData();
  }

  static getValue(SightingStatus, index){
    let key = Object.keys(SightingStatus[index]);
    let val = SightingStatus[index][key];
    let keyString = key.toString();
    if (keyString.localeCompare("stringValue") != 0)
        return "";
    //alert(val);
    return val;
    //alert(JSON.stringify(SightingStatus[0].value))
  }
  static getLongValue(SightingStatus, index){
    let key = Object.keys(SightingStatus[index]);
    let val = SightingStatus[index][key];
    let keyString = key.toString();
    if (keyString.localeCompare("longValue") != 0)
        return "";
    //alert(val);
    return val;
    //alert(JSON.stringify(SightingStatus[0].value))
  }
  renderSightingStatusTable(SightingStatus) {
     
    var greenTextStyle = {
      color: 'green',
    }
    var redTextStyle = {
      color: 'red',
    }
    var yelloTextStyle = {
      color: 'orange',
    }
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Id</th>
            <th>File Name</th>
            <th>Prediction</th>
            <th>Confidence</th>
            <th>Submitted</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {SightingStatus.map(SightingStatus =>
            <tr key={SightingStatus.sighting_uuid} >
              <td>{
                    SightingStatus.sighting_uuid
              }</td>
              <td>{
                    SightingStatus.original_file_name
              }</td>
              <td>
                {SightingStatus.sighting_status && 
                <p style={SightingStatus.sighting_status.predicted_label == 'Black Bear' ? greenTextStyle : null}>
                    { 
                      SightingStatus.sighting_status.predicted_label
                          
                    }</p> }
              </td>
              <td>
              {SightingStatus.sighting_status && 
                  
                  SightingStatus.sighting_status.confidence
                    
              }
              </td>
              <td>{SightingStatus.submitted_timestamp && 
                    SightingStatus.submitted_timestamp
              }
              </td>
              <td>
                {SightingStatus.sighting_status && 
                <p style={ SightingStatus.sighting_status.status_text.includes('rejected') ? redTextStyle : ( SightingStatus.sighting_status.status_text.includes('confirmed') ? greenTextStyle: yelloTextStyle)  }>{
                        SightingStatus.sighting_status.status_text
                  }</p>
                }
              </td>
              
            </tr>
          )}
        </tbody>
      </table>
    );
  }
  

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderSightingStatusTable(this.state.SightingStatus);

    return (
      <div>
        <h1 id="tabelLabel" >Sighting Status</h1>
        
        {contents}
      </div>
    );
  }

  async populateSightingStatusData() {
    //const response = await fetch('https://swapi.py4e.com/api/people/');
    const response = await fetch ('https://5emo37ia5h.execute-api.us-east-1.amazonaws.com/prod/status/');

    const data = await response.json();
    

    //alert (JSON.stringify(data.records[0][2][1]));
    this.setState({ SightingStatus: data, loading: false });
  }
}
