import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import MapContainer from './components/MapContainer';
import {SightingStatus} from './components/SightingStatus';
import {ReportSighting} from './components/ReportSighting';
import {SightingReview} from './components/SightingReview';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={MapContainer} />
        <Route path='/sightingstatus' component={SightingStatus} />
        <Route path='/reportsightings' component={ReportSighting} />
       
        <Route path='/sightingreview' component={() => { 
              window.location.replace( 'https://i67xa4inbo.labeling.us-east-1.sagemaker.aws'); 
              return null;
          }}/>
      </Layout>
    );
  }
}
