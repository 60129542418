import React, { Component } from 'react';
import Iframe from 'react-iframe'

export class SightingReview extends Component {
 

  constructor(props) {
    super(props);
    this.state = {  };
  }

  componentDidMount() {
   
  }


  render() {
    
    return (
      <div>
        <Iframe url="https://i67xa4inbo.labeling.us-east-1.sagemaker.aws"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
      </div>
    );
  }

}
