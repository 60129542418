import * as React from 'react';
import {PureComponent} from 'react';

export default class ControlPanel extends PureComponent {
  render() {
      
    const {startTime, endTime, onChangeDay, allDay, onChangeAllDay, selectedTime} = this.props;
   
    
    const day = 24 * 60 * 60 * 1000;
    const days = Math.round((endTime - startTime) / day);

    const _onChangeDay = evt => {
      const daysToAdd = evt.target.value;
      // add selected days to start time to calculate new time
      
      const newTime = new Date( startTime.getTime() + daysToAdd * day);
      
      //alert(startTime + ' ' + newTime);
      onChangeDay(newTime);
    };

    const formatTime = time => {
      const date = new Date(time);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };

    const controlPanelStyle = 
    {
       
            position: 'absolute',
            top: '0',
            right: '0',
            maxWidth: '320px',
            background: '#fff',
            boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
            padding: '12px 24px',
            margin: '20px',
            fontSize: '13px',
            lineHeight: '2',
            color: '#6b6b76',
            outline: 'none',
            textTransform: 'uppercase'
          
    }
    const labelStyle = 
    {
        paddingRight: '20px'
    }
    
    return (
    
      <div className="control-panel" style = {controlPanelStyle} >
         
        <h3>Bear Map</h3>
        <p>
          Map showing sightings
          <br />
          from <b>{formatTime(startTime)}</b> to <b>{formatTime(endTime)}</b>.
        </p>
        <hr />
        <div className="input">
          <label style={labelStyle}>All Days</label>
          <input
            type="checkbox"
            name="allday"
            checked={allDay}
            onChange={evt => onChangeAllDay(evt.target.checked)}
          />
        </div>
        <div className={`input ${allDay ? 'disabled' : ''}`}>
          <label style = { labelStyle}>Each Day: {formatTime(selectedTime)}</label>
          <input
            type="range"
            disabled={allDay}
            value = { Math.round(( selectedTime - startTime) / day) }
            min={0}
            max={days}
            step={1}
            onChange={_onChangeDay}
          />
        </div>
        <hr />
        
        
      </div>
    );
  }
}